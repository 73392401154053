.PdfHighlighter {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  /* Override 100% height with intrinsic height (if supported) to prevent layout issues
  (e.g., the bottom of the PDFHighlighter being overlapped by the DocumentSelector when the Confidence Banner is visible). */
  height: -webkit-fill-available;
}

.PdfHighlighter__highlight-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfHighlighter__tip-container {
  z-index: 6;
  position: absolute;
}

.PdfHighlighter--disable-selection {
  user-select: none;
  pointer-events: none;
}

.PdfHighlighter--feedback-mode {
  cursor: crosshair;

  .Highlight__part {
    cursor: crosshair;
  }

  .textLayer *:not(.MuiBox-root) {
    cursor: crosshair;
  }
}
